import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import kaito from '../../assets/kaito_ai.JPG'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={kaito} alt="Kaito" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon' />
              <h5>Experience</h5>
              <small>3+ Years Working</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon' />
              <h5>Clients</h5>
              <small>10+ Worldwide</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon' />
              <h5>Project</h5>
              <small>10+ Completed</small>
            </article>
          </div>

          <p>
            I am a skilled full-stack developer with an Engineering degree in Computer Science from Vietnam-Korea University of Information and Communication Technology. I have experience working with various technologies, including Laravel, Vue.js, and AWS. I’ve contributed to multiple projects, from hotel management platforms to job search engines, excelling in backend development, API integration, and cloud deployment. Additionally, I have a solid foundation in leading teams and optimizing system architectures for scalable and efficient performance.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About