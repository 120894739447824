import React from 'react'
import './services.css'
import { BiCheck } from 'react-icons/bi'

const Services = () => {
  return (
      <section id='services'>
        <h5>What I Offer</h5>
        <h2>Services</h2>

        <div className="container services__container">
          <article className="service">
            <div className="service__head">
              <h3>UI/UX Design</h3>
            </div>

            <ul className="service__list">
              <li>
                <BiCheck className='service__list-icon' />
                <p>Wireframing and Prototyping</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>User Research and Analysis</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Interactive Design</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Usability Testing</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Visual Design</p>
              </li>
            </ul>
          </article>
          {/* END UI/UX DESIGN */}
          <article className="service">
            <div className="service__head">
              <h3>Web Development</h3>
            </div>

            <ul className="service__list">
              <li>
                <BiCheck className='service__list-icon' />
                <p>Responsive Web Design</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Frontend Development</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Backend Development</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>API Integration</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Performance Optimization</p>
              </li>
            </ul>
          </article>
          {/* END WEB DEVELOPMENT */}
          <article className="service">
            <div className="service__head">
              <h3>AWS Cloud Services</h3>
            </div>

            <ul className="service__list">
              <li>
                <BiCheck className='service__list-icon' />
                <p>AWS Cloud Deployment</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>AWS Architecture Design</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Amazon EC2, S3, and RDS Management</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Infrastructure as Code (IaC)</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Cloud Security with AWS IAM and VPC</p>
              </li>
            </ul>
          </article>
          {/* END AWS CLOUD SERVICES */}
        </div>
      </section>
  )
}

export default Services