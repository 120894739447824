import React from 'react'
import './portfolio.css'
import landbrain from '../../assets/landbrain.webp'
import carshare from '../../assets/carshare.webp'
import daisouya from '../../assets/daisouya.webp'
import ekkyolabo from '../../assets/ekkyolabo.webp'
import IMG5 from '../../assets/portfolio5.png'
import IMG6 from '../../assets/portfolio6.jpg'

// DO NOT USE THE IMAGES IN PRODUCTION

const data = [
  {
    id: 1,
    image: landbrain,
    title: 'Landbrain',
    description: 'LANDBRAIN MARCHÉ is a matching platform that connects commercial property operators with kitchen car vendors.',
    github: 'https://github.com/KaitoKid1612',
    demo: 'https://marche.landbrain.com/'
  },
  {
    id: 2,
    image: carshare,
    title: 'Car share',
    description: 'The website serves as a platform for car owners looking to share their vehicles with users in need. Users can easily search for and book a vehicle based on their preferences, such as car type, time, and location.',
    github: 'https://github.com/KaitoKid1612',
    demo: 'https://kers6050.com/'
  },
  {
    id: 3,
    image: daisouya,
    description: 'DAISOUYA is a system for finding delivery drivers. The system offers a range of diverse functionalities, not only for delivery drivers but also for delivery personnel and managers.',
    title: 'Daisouya',
    github: 'https://github.com/KaitoKid1612',
    demo: 'https://ks-ring.co.jp/'
  },
  {
    id: 4,
    image: ekkyolabo,
    title: 'Ekkyolabo',
    description: 'Ekkyo Labo is a system aimed at advising Japanese businesses wishing to expand their investment activities into international markets.',
    github: 'https://github.com/KaitoKid1612',
    demo: 'https://ekkyo-labo.jp/diagnose/'
  },
  {
    id: 5,
    image: IMG5,
    title: 'Likely',
    description: 'Online job search platform that connects job seekers with potential employers, providing a user-friendly interface for job searches and applications.',
    github: 'https://github.com/KaitoKid1612',
    demo: 'https://dribbble.com/shots/19315107-Juno-Mobile-Website-Design'
  },
  {
    id: 6,
    image: IMG6,
    title: 'OctoCord',
    description: 'Management system for Discord server bots, allowing users to conveniently utilize server functions via our application through bots.',
    github: 'https://github.com/KaitoKid1612',
    demo: 'https://dribbble.com/shots/19315107-Juno-Mobile-Website-Design'
  },
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, description, github, demo}) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <p>{description}</p>
                <div className='portfolio__item-cta'>
                  <a href={github} className='btn' target='_blank'>Github</a>
                  <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
                </div>
            </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio